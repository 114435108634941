/* font import Poppins & Ubuntu */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&family=Ubuntu:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* variables */
:root {
  --primary-font: "Poppins", sans-serif;
  --primary-font-light: "Poppins-light", sans-serif;
  --primary-font-medium: "Poppins-medium", sans-serif;
  --secondary-font: "Ubuntu", sans-serif;
  --primary-color: #2bc5b4;
  --secondary-color: #f79256;
  --third-color: #524cb3;
  --site-grey-light: #f8f8f8;
  --site-grey-dark: #626262;
  --site-black: #1c1d3e;
}

html {
  scroll-behavior: smooth;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* General styles */
* {
  box-sizing: border-box;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
}
p {
  margin: 10px 0;
}

.m-auto {
  margin: 0 auto !important;
}

.w-25 {
  width: 25%;
}
.w-33 {
  width: 33.33%;
}
.w-50 {
  width: 50%;
}

.w-65 {
  width: 65%;
}

.w-75 {
  width: 75%;
}
.w-100 {
  width: 75%;
}

.p-10 {
  padding: 10px;
}
.pr-50 {
  padding-right: 50px !important;
}
.pr-100 {
  padding-right: 100px !important;
}

.p-30 {
  padding: 30px !important;
}
.p-25 {
  padding: 25px !important;
}
.p-50 {
  padding: 50px !important;
}
.p-75 {
  padding: 75px !important;
}
.p-100 {
  padding: 100px !important;
}

.px-25 {
  padding: 0 25px !important;
}
.px-50 {
  padding: 0 50px !important;
}
.px-75 {
  padding: 0 75px !important;
}
.px-100 {
  padding: 0 100px !important;
}

.py-25 {
  padding: 25px 0 !important;
}
.py-50 {
  padding: 50px 0 !important;
}
.py-75 {
  padding: 75px 0 !important;
}
.py-100 {
  padding: 100px 0 !important;
}

.my-25 {
  padding: 25px 0 !important;
}
.my-50 {
  padding: 50px 0 !important;
}
.my-75 {
  padding: 75px 0 !important;
}
.my-100 {
  padding: 100px 0 !important;
}

.pt-25 {
  padding-top: 25px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-75 {
  padding-top: 75px !important;
}
.pt-100 {
  padding-top: 100px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-50 {
  padding-bottom: 50px !important;
}
.pb-75 {
  padding-bottom: 75px !important;
}
.pb-100 {
  padding-bottom: 100px !important;
}
.text-left {
  text-align: left;
}
.justify-flex__center {
  justify-content: center;
}
.justify-flex__end {
  justify-content: flex-end;
}

.btn-primary {
  font-family: var(--primary-font);
  padding: 12px 35px;
  border-radius: 9px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(91deg, #2bc5b4 3%, #35ddcb 100%);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: none;
  color: #fff;
  font-size: 14px;
}

.btn-primary:hover {
  background-image: linear-gradient(91deg, #35ddcb 3%, #2bc5b4 100%);
  transition: all 4s ease-in-out;
  cursor: pointer;
}

img {
  width: 100%;
}
input,
textarea {
  border: transparent;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #f4f8ff;
  transition: all 0.3s ease-in-out;
}

.py-1 {
  padding: 10px 0;
}

.localty-app img.mobile-customers-img {
  width: 100% !important;
}
.localty-app .btn-group__flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.localty-app .btn-image {
  display: flex;
  width: 65px;
}

.localty-app img.number-icons {
  width: 100px;
  height: 100px;
}

.localty-app container-body__card-content {
  padding: 0 10px;
}

.localty-app .loyalty-how-it-works .container-body__card-left {
  display: flex;
  padding: 0 !important;
  align-items: center;
}

.localty-app .loyalty-how-it-works .container-body__card-content {
  padding: 0 20px;
}

section.container.join-us .container-body {
  position: relative;
}

section.container.join-us .container-body .join-us__bottom {
  position: absolute;
  bottom: 25%;
  padding: 0 150px;
  text-align: center;
}

.container-body.loyalty-security .loyalty-security_titlle {
  color: #5d56c4;
  font-size: 20px;
  font-weight: 600;
  margin: 10px 0;
}

.container-body.loyalty-security p {
  font-size: 16px !important;
  margin-bottom: 20px;
}

.head-bg {
  background-image: url("./images/head-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.container-heading__title {
  font-family: Ubuntu;
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1c1d3e;
}
.container-heading__sub-title {
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1c1d3e;
}
.container-heading__sub-header {
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #1c1d3e;
}

section.container.subscribtion p {
  color: #626262;
  margin: 10px 0;
}

.privacy .container-heading__title {
  padding: 10px 0;
}
.privacy p {
  padding: 10px 0;
}
.privacy p.contact {
  padding: 4px 0;
  margin: 0;
  margin-left: 25px;
}
.nodify-title {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: 600;
}
.privacy ul {
  padding: 0 20px;
}
.privacy ul li {
  list-style-type: disc;
  padding: 10px 0;
}
.privacy ul.sub-list li {
  list-style: decimal !important;
}
.privacy ul.alphabet-list li {
  list-style: lower-alpha;
}
.font-italic {
  font-style: italic;
}

@media (min-width: 768px) and (max-width: 1024px) {
}

.nav-bar {
  width: 100%;
  padding: 35px 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.ReactModal__Overlay {
  z-index: 99;
}
.Modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background-color: papayawhip;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rebeccapurple;
}
.active-top .nav-bar {
  padding: 20px 75px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 767px) {
  .nav-bar {
    width: 100% !important;
    padding: 10px 0 !important;
  }
  .active-top .nav-bar {
    padding: 10px 0 !important;
  }
  .head-bg {
    background-size: 175%;
    overflow: hidden;
  }
  .privacy .container-body > div {
    padding: 0px !important;
  }

  .privacy .container-heading__title {
    font-size: 18px;
  }
  .contact-us.head-bg {
    padding: 100px 20px 0 !important;
  }
  /* .btn-primary{
    padding: 12px 20px;
  } */
  /* h1.hero-main__title {
    font-size: 36px !important;
  } */
}

.active-top a {
  /* color: #fff; */
  transition: all 0.5s ease-in-out;
}

.active-top a.active:after {
  border-bottom: solid 3px #fff;
}

.active-top .logo_img {
  background-image: url("./images/newlogo.png") !important;
}

.animatable {
  /* initially hide animatable objects */
  visibility: hidden;

  /* initially pause animatable objects their animations */
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -ms-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

/* show objects being animated */
.animated {
  visibility: visible;

  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;

  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

button:focus,
div:focus {
  outline: none;
}

.required-asterisk {
  color: #ff2500;
}
.Toastify__toast--success {
  background: #fff !important;
  color: #000 !important;
  padding: 10px 20px !important;
}

.Toastify__close-button {
  color: #000 !important;
  right: 5px !important;
  position: absolute !important;
  top: 5px !important;
}

.subscribeButton {
  cursor: pointer;
}
.subscribeButton:focus {
  outline: none;
}
