.partner-container p {
  font-weight: 400;
}

.partner-intro {
  max-width: 800px;
  margin: auto;
  padding-top: 2rem;
}
.modal-close {
  position: absolute;
  right: 70px;
  top: 30px;
  font-size: 35px;
  color: var(--primary-color);
  cursor: pointer;
}
.partner-container {
  max-height: 90vh;
}

.partner-container .container-heading__image {
  width: 50px;
}

.partner-form input,
.partner-form textarea {
  border-radius: 25px;
  background-color: #fff;
  padding: 1em;
  border: none;
  font-family: "Lato", sans-serif;
  width: 100%;
  margin: 0;
  position: absolute;
  border-radius: 10px;
  border: 1px solid grey;
}

/* .partner-form input:valid {
	border-bottom: 3px solid #2bc5b4;
	border-radius: 0;
	width: 90%;
} */

/* .partner-form input:invalid {
	border-bottom: 2px solid #f79256;
	border-radius: 0;
	width: 90%;
} */

.partner-form input:focus {
  box-shadow: none;
  border-bottom: 2px solid #2bc5b4;
  /* width: 93%; */
  border-radius: 0;
}

.form-container {
  position: relative;
  max-width: 800px;
  margin: auto;
  padding: 2rem 0;
}

.partner-form > ul {
  margin: 0;
}

/* terms of trade checkbox styles */
.accept-conditions {
  display: flex;
  border-radius: 10px;
  background-color: #eee;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  margin-bottom: 3rem;
}

.accept-conditions > input {
  width: auto;
}

.accept-conditions > label {
  margin-bottom: 0;
}

/* form fields */
.partner-form > ul {
  list-style-position: inside;
  padding-left: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: auto;
  gap: 0 1rem;
}

.partner-form > ul > li:nth-of-type(3) {
  grid-column: 1/-1;
}

.partner-form > ul > li:nth-of-type(4) {
  grid-column: 1/-1;
}

.partner-form > ul > li {
  position: relative;
  display: flex;
  margin: 0 0 1.5rem 0;
  list-style: none;
  padding-left: 0;
  height: 50px;
}

.partner-form > ul > li > label {
  position: absolute;
  top: -5px;
  left: 15px;
  background-color: white;
  margin: 0;
  padding: 0 10px;
  font-size: 0.8rem;
  z-index: 9;
}

/* form fields icons */
.partner-form > ul > li > .fas {
  width: 20px;
  height: auto;
  margin-left: 14px;
  margin-top: 22px;
  color: #2bc5b4;
}

.partner-form > ul > li > p {
  justify-content: flex-end;
  position: absolute;
  bottom: -40px;
  font-size: 0.7rem;
  color: grey;
}

select {
  /* -webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; */
  border: 4px solid red;
  border-radius: 10px;
  padding: 1rem;
}

/* asterisk for required fields */
.asterisk {
  color: #faa572;
}

input#acceptConditions {
  position: relative;
}

@media screen and (max-width: 767px) {
  .accept-conditions > label {
    font-size: 12px;
  }
  .partner-container .container-heading {
    padding: 25px !important;
  }
  .partner-form > ul > li {
    width: 95%;
  }
  .partner-container p {
    font-size: 12px;
  }
  .partner-container .container-heading__image {
    width: 35px;
  }
  .partner-form > ul > li > label {
    font-size: 0.6rem;
  }
  .partner-container h1 {
    font-size: 18px;
  }
  .modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 30px;
  }

  .ReactModal__Content {
    width: 90% !important;
  }

  .partner-form > ul {
    grid-template-columns: auto;
  }
}

.ReactModal__Content::-webkit-scrollbar {
  display: none;
}
.ReactModal__Content {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 320px) {
  .accept-conditions > label {
    font-size: 10px;
  }
  .partner-form > ul > li > label {
    font-size: 0.5rem;
  }
}
