.loyalty-nav nav.logo .logo_img {
  background-image: url("../images/greenLogo.png");
  background-repeat: no-repeat;
}

.loyalty .hero-banner {
  background-image: url("../images/loyalty-bg0.svg") !important;
  background-position: 0 -10px;
  background-size: 800px;
  min-height: 500px;
}

.loyalty-how-it-works {
  background-image: url("../images/Intersect.svg") !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.loyalty-bg-container {
  background-image: url("../images/bg-container.svg") !important;
  background-repeat: no-repeat;
  background-size: cover;
}
.loyalty-banner .h1.hero-main__title {
  font-size: 36px;
}

.loyalty-banner .hero-banner__container {
  display: flex;
  align-items: center;
}

.loyalty .hero-banner__container {
  display: flex;
  align-items: center;
}

.loyalty .hero-banner__container .hero-paragraph {
  padding: 15px 0;
}
form.container-body__from-send-app .input-group {
  position: relative;
}

.container-body__from-send-app input.form-control {
  width: 100%;
  height: 50px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  padding: 15px 35px;
}
.container-body__from-send-app .share-button {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  padding: 12px 38px;
  background: #2bc5b4;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  color: #fff;
}

.loyalty-how-it-works .app-details__btns {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
  gap: 10px;
}
/* .loyalty .hero-banner{
  width: 100%;
  position: relative;
  overflow: hidden;
  max-height: 800px;
  background-image: url("../images/home/home-bg-main1.svg");
  background-repeat: no-repeat;
  background-size: contain;
} */
.loyalty .hero-banner {
  background-color: inherit;
}

.loyalty .hero-banner .hero-banner__container {
  padding: 200px 20px 50px;
}
.loyalty .btn-group__flex {
  justify-content: start;
}
.loyalty .btn-image {
  margin-left: 15px;
}
@media (min-width: 768px) and (max-width: 1024px) {
}

@media screen and (max-width: 767px) {
  .loyalty .hero-banner {
    background-image: url("../images/loyalty-bg0.svg");
    background-position: 98% 0px;
    /* an: all 0.5s ease-in-out; */
    animation: fadeIn 1s ease-in-out;
  }
  .loyalty-nav nav.logo .logo_img {
    background-image: url("../images/newlogo.png");
    background-repeat: no-repeat;
  }
  .loyalty-bg-container {
    background-size: 400%;
    background-position: -200px;
  }
  .localty-app .btn-group__flex {
    margin: 15px 0;
  }
  .loyalty .btn-group__flex {
    display: block;
  }
  .loyalty .hero-banner_container-left img {
    width: 100%;
  }
  .join-us .container-heading__title {
    font-size: 24px;
    margin: 20px 0;
  }
  .container.join-us {
    padding: 50px 20px;
  }
  .localty-app .hero-banner_container-left .btn-group__flex .btn-image {
    display: block;
    margin: 15px auto;
  }
  .container-body.loyalty-security p {
    margin-bottom: 30px;
  }
  .hero-banner_container-left .btn-group__flex .btn-primary {
    padding: 12px 50px;
  }
  .loyalty-banner .hero-banner__container,
  .localty-app .loyalty-how-it-works .container-body__card-left {
    display: block;
  }
  .loyalty-how-it-works .app-details__btns {
    display: block;
  }
  .container-body__from-send-app .share-button,
  .container-body__from-send-app input.form-control {
    position: relative;
    border-radius: 15px;
  }
  .loyalty-how-it-works .app-details__btns .share__link .app-details__btn {
    margin: 0 auto;
  }
  /* .loyalty-banner h1.hero-main__title {
    font-size: 28px;
  } */
  section.container.join-us .container-body .join-us__bottom {
    position: relative;
    bottom: 25%;
    padding: 0;
  }
  .localty-app .loyalty-how-it-works .container-body__card-right {
    display: none;
  }
}
