.footer-container {
  background-image: url("../images/footer-images/footer-bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 50px 0 0;
}
.footer-container img#footerLogo {
  width: 115px;
}
.footer-container-section {
  background: #444f60;
}
.footer-container .container {
  display: grid;
  grid-gap: 50px;
  grid-template-columns: 1.25fr 1.5fr 1fr 0.75fr;
  width: 1100px;
  margin: 0 auto;
}

.apple-share {
  background-image: url("../images/footer-images/appstore.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.playstore-share {
  background-image: url("../images/footer-images/googleplay.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.share__link .app-details__btn {
  width: 150px;
  height: 50px;
}
.head-secondary {
  margin: 10px 0;
  color: #2bc5b4;
}
.footer-container__section.company-address {
  display: flex;
  justify-content: space-between;
}
.loyaltyCompany {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.5; */
  letter-spacing: normal;
  text-align: left;
  color: #98a9c3;
  margin: 0 0 10px;
}
.flag-title {
  display: flex;
}

.flag-title img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}

h6.country-name {
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #98a9c3;
  margin-bottom: 15px;
}

.company-address p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #98a9c3;
  margin: 0;
}

.footer-container__section.navigation-links a {
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #98a9c3;
  margin-bottom: 10px;
}

.footer-container__section.navigation-links a:hover {
  color: #2bc5b4;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .footer-container {
    background-size: contain;
    padding: 50px 0 0;
  }

  section.container.benefit-everyone .container-body,
  section.container.how-it-works .container-body {
    padding: 20px;
  }
  .footer-container .container {
    display: block;
    width: 100%;
    padding: 20px 100px;
  }
  .footer-container .loyaltyCompany,
  .footer-container__section.company-logo {
    text-align: center;
  }
  .footer-container__section.company-logo {
    padding: 20px 0;
  }
  .footer-container__section.company-address {
    justify-content: space-between;
  }

  .footer-container .app-details__btns {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0;
  }
  section.container.contact-us .container-body .container-body__from {
    padding: 50px;
  }
}
@media screen and (max-width: 767px) {
  .footer-container .container {
    display: block;
    width: 100%;
    padding: 20px 100px 50px;
  }

  .footer-container {
    background-size: contain;
    padding: 50px 0 0;
  }
  .footer-container .loyaltyCompany,
  .footer-container__section.company-logo {
    /* text-align: center; */
    margin: 0;
  }
  .company-social__media {
    margin-top: 10px;
  }
  .footer-container__section.company-logo {
    padding: 20px 0;
  }
  .footer-container__section.company-address,
  .footer-container .app-details__btns {
    display: block !important;
  }
  .company-address__left,
  .company-address__right {
    margin: 20px 0;
  }
  .footer-container .app-details__btns {
    display: flex;
    justify-content: space-evenly;
    padding: 20px 0;
  }
}

a.company-social__media-link {
  width: 40px;
  color: #2cc5b4;
  font-size: 20px;
  transition: all 0.2s ease-in;
}

.company-social__media {
  display: flex;
}

a.company-social__media-link:hover {
  transform: scale(1.2);
  border-radius: 50%;
}
