.how-it-works-container-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  margin-top: 5%;
}
.how-it-works-left-container {
  width: 100%;
}
.how-it-works-middle-container {
  width: 100%;
}
.how-it-works-right-container {
  width: 100%;
}
@media (max-width: 700px) and (min-width: 564px) {
	
  .how-it-works-left-container {
    width: 70%;
    margin: auto;
    margin-bottom: 70px;
  }
  .how-it-works-middle-container {
    width: 70%;
    margin: auto;
    margin-bottom: 70px;
  }
  .how-it-works-right-container {
    width: 70%;
    margin: auto;
    margin-bottom: 70px;
  }
  .how-it-works-description {
    margin-top: 0px !important;
  }
}

@media (max-width: 564px) {
  .how-it-works-left-container {
    width: 90%;
    margin: auto;
    margin-bottom: 70px;
  }
  .how-it-works-middle-container {
    width: 90%;
    margin: auto;
    margin-bottom: 70px;
  }
  .how-it-works-right-container {
    width: 90%;
    margin: auto;
    margin-bottom: 70px;
  }
  .how-it-works-description {
    margin-top: 0px !important;
  }
}
.how-it-works-image-fit-container {
  width: 60%;
  margin: auto;
  position: relative;
}
@media (min-width: 700px) {
  .one-two-connect {
    position: absolute;
    width: 97%;
    bottom: -8%;
    right: -75%;
    z-index: -1;
  }
  .two-three-connect {
    position: absolute;
    width: 97%;
    top: -5%;
    right: -75%;
    z-index: -1;
  }
}
@media (max-width: 700px) {
  .one-two-connect {
    display: none;
  }
  .two-three-connect {
    display: none;
  }
}
.one-top-left {
  position: absolute;
  width:75px;
  top: 20%;
  left: -5%;
}
.one-bottom-left {
  position: absolute;
  width: 130px;
  bottom: 0;
  left: -10%;
  z-index: -1;
}
.two-top-left {
  position: absolute;
  width: 75px;
  top: 20%;
  left: -5%;
}
.two-bottom-left {
  position: absolute;
  width: 130px;
  bottom: 20%;
  left: -15%;
  z-index: -1;
}
.two-bottom-right {
  position: absolute;
  width: 60px;
  bottom: 5%;
  right: -2%;
}
.three-top-left {
  position: absolute;
  width: 80px;
  top: 15%;
  left: -10%;
}
.three-bottom-right {
  position: absolute;
  width: 60px;
  bottom: 25%;
  right: -2%;
}
.three-middle-right {
  position: absolute;
  width: 60px;
  top: 45%;
  right: -20%;
}
.three-top-right {
  position: absolute;
  width: 170px;
  top: -15%;
  right: -30%;
  z-index: -1;
}
.how-it-works-image-fit {
  width: 100%;
}
.how-it-works-description {
  width: 80%;
  margin: auto;
  text-align: center;
  margin-top: 40px;
}
.how-it-works-description-heading {
  font-family: Ubuntu;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: 1.44px;
  text-align: center;
  color: #1c1d3e;
}
.how-it-works-description p {
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 20px 0;
  font-size: 14px;
  text-align: center;
  padding-left: 30px;
  color: #1c1d3e;
}
.text-conatiner {
  position: relative;
}
.number-indicator {
  position: absolute;
  left: 0;
  width: 50px;
  height: 50px;
  border: 2px solid #2dcdbc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
  left: -35px;
  background-color: #fff;
}
